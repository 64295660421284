const { ifExist, ifExistOnly } = await import(/* webpackChunkName: "utility" */ 'Common/Scripts/Common/utility');


// styles

import(/* webpackChunkName: "slick" */ 'slick-carousel/slick/slick.scss');

import(/* webpackChunkName: "global" */ 'Common/Frontend/components/global.scss');

import(/* webpackChunkName: "featureBase" */ 'Feature/Base/code/Styles/featureBase.scss');

import(/* webpackChunkName: "footer" */ 'Feature/Navigation/code/Footer/Styles/footer.scss');

import(/* webpackChunkName: "backtotop" */ 'Feature/Navigation/code/Footer/Styles/backtotop.scss');

import(/* webpackChunkName: "pageSection" */ 'Feature/Common/code/PageSection/Styles/pageSection.scss');

import(/* webpackChunkName: "richtext" */ 'Foundation/ContentElements/code/Richtext/Styles/richtext.scss');

import(/* webpackChunkName: "headline" */ 'Foundation/ContentElements/code/Common/Styles/headline.scss');

import(/* webpackChunkName: "linkButtonElem" */ 'Feature/ContentElements/code/LinkButton/Styles/linkButtonElem.scss');

import(/* webpackChunkName: "columns" */ 'Feature/Common/code/Styles/columns.scss');

// 3rd party common libraries
() => import(/* webpackChunkName: "vue" */ 'vue');

() => import(/* webpackChunkName: "axios" */ 'axios');

() => import(/* webpackChunkName: "jquery" */ 'jquery');

() => import(/* webpackChunkName: "vue-mq" */ 'vue-mq');

() => import(/* webpackChunkName: "vuetify" */ 'vuetify');

() => import(/* webpackChunkName: "shariff" */ 'shariff');

() => import(/* webpackChunkName: "parsleyjs" */ 'parsleyjs');

() => import(/* webpackChunkName: "lazysizes" */ 'lazysizes');

// () => import(/* webpackChunkName: "moment" */ 'moment');

() => import(/* webpackChunkName: "v-tooltip" */ 'v-tooltip');

() => import(/* webpackChunkName: "picturefill" */ 'picturefill');

// () => import(/* webpackChunkName: "bootstrap" */ 'bootstrap');

() => import(/* webpackChunkName: "vue2-filters" */ 'vue2-filters');

() => import(/* webpackChunkName: "mediaelement" */ 'mediaelement');

() => import(/* webpackChunkName: "slick-carousel" */ 'slick-carousel');

() => import(/* webpackChunkName: "lodash" */ 'lodash');

() => import(/* webpackChunkName: "index" */ 'lodash.debounce/index');

() => import(/* webpackChunkName: "jquery-match-height" */ 'jquery-match-height');

() => import(/* webpackChunkName: "intersection-observer" */ 'intersection-observer');

() => import(/* webpackChunkName: "jquery-validation" */ 'jquery-validation');

() => import(/* webpackChunkName: "jquery-ajax-unobtrusive" */ 'jquery-ajax-unobtrusive');

() => import(/* webpackChunkName: "modal" */ 'bootstrap/js/dist/modal');

() => import(/* webpackChunkName: "collapse" */ 'bootstrap/js/dist/collapse');

() => import(/* webpackChunkName: "jquery-validation-unobtrusive" */ 'jquery-validation-unobtrusive');

// () => import(/* webpackChunkName: "de" */ 'mediaelement/src/js/languages/de');

() => import(/* webpackChunkName: "a11y" */ 'mediaelement-plugins-custom-akme/dist/a11y/a11y');

() => import(/* webpackChunkName: "a11y-i18n" */ 'mediaelement-plugins-custom-akme/dist/a11y/a11y-i18n');

// local common libraries

() => import(/* webpackChunkName: "search" */ 'Common/Frontend/General/global-js/search');

() => import(/* webpackChunkName: "DropdownHelper" */ 'Common/Frontend/General/global-js/DropdownHelper');

() => import(/* webpackChunkName: "SelfInitComponent" */ 'Common/Frontend/General/global-js/SelfInitComponent');

() => import(/* webpackChunkName: "SlidingMenuPlugin" */ 'Common/Frontend/General/global-js/SlidingMenuPlugin');

() => import(/* webpackChunkName: "header" */ 'Common/Frontend/components/organisms/header/header.cmp');

() => import(/* webpackChunkName: "lotteryTypeTab" */ 'Feature/LotteryPayAndPlay/code/Scripts/lotteryTypeTab');


//  on-demand styles

if (ifExist('.am-form')) import(/* webpackChunkName: "forms" */ 'Feature/Forms/code/Styles/forms.scss');

if (ifExist('.custom-modal,.log-out-custom-modal')) import(/* webpackChunkName: "customModal" */ 'Feature/Base/code/Styles/customModal.scss');

if (ifExist('.footer__simple')) import(/* webpackChunkName: "simpleFooter" */ 'Feature/Navigation/code/Styles/SimpleFooter/simpleFooter.scss');

if (ifExist('.customer-tickets__tile')) import(/* webpackChunkName: "customerTickets" */ 'Feature/CustomerProfile/code/Styles/customerTickets.scss');


// scripts

import(/* webpackChunkName: "cookie" */ 'Foundation/Common/code/Scripts/Cookie/cookie');

import(/* webpackChunkName: "ExperienceEditor" */ 'Common/Scripts/Sitecore/ExperienceEditor');

//  on-demand scripts
if (ifExist('.am-form,.service-form')) import(/* webpackChunkName: "formValidation" */ 'Feature/Forms/code/Scripts/formValidation');

if (ifExist('.sc-form')) import(/* webpackChunkName: "scForms" */ 'Feature/Forms/code/Scripts/scForms');

if (ifExist('.tabs')) import(/* webpackChunkName: "tabs" */ 'Feature/ContentElements/code/Scripts/Tabs/tabs');

if (ifExist('.sitecore-component')) import(/* webpackChunkName: "mainSitecore" */ 'Common/Scripts/Sitecore/mainSitecore');

if (ifExist('.progressbar')) import(/* webpackChunkName: "progressbar" */ 'Feature/Common/code/Scripts/Common/progressbar');

if (ifExist('.pagination')) import(/* webpackChunkName: "pagination" */ 'Feature/Common/code/Scripts/Pagination/pagination');

if (ifExist('.winning-service')) import(/* webpackChunkName: "winningService" */ 'Feature/WinningService/code/Scripts/winningService');

if (ifExist('.main-slider')) import(/* webpackChunkName: "mainSlider" */ 'Feature/ContentElements/code/Scripts/Slider/mainSlider');

if (ifExist('.skip-links__container')) import(/* webpackChunkName: "WCAGSkipLinks" */ 'Feature/WCAGSkipLinks/code/Scripts/WCAGSkipLinks');

if (ifExist('.lightbox-overlay-container, [target=_lightbox]')) import(/* webpackChunkName: "lightbox" */ 'Feature/Lightbox/Code/Scripts/Lightbox/lightbox');

if (ifExist('.explanation-overlay-container')) import(/* webpackChunkName: "explanation" */ 'Feature/Lightbox/Code/Scripts/Lightbox/explanation');

if (ifExist('.am-general-table')) import(/* webpackChunkName: "generalTable" */ 'Feature/ContentElements/code/Scripts/GeneralTable/generalTable');

if (ifExist('.social-sharing-container')) import(/* webpackChunkName: "socialSharing" */ 'Feature/Common/code/Scripts/SocialSharing/socialSharing');

if (ifExist('.focusbox-element-container')) import(/* webpackChunkName: "focusbox" */ 'Feature/ContentElements/code/Scripts/Focusbox/focusbox');

if (ifExist('.content-stage-container')) import(/* webpackChunkName: "contentStage" */ 'Feature/ContentElements/code/Scripts/ContentStage/contentStage');

if (ifExist('.percentage-cycle__wrapper')) import(/* webpackChunkName: "percentageCycle" */ 'Feature/ContentElements/code/Scripts/PercentageCycle/percentageCycle');

if (ifExist('.winning-stories-gallery')) import(/* webpackChunkName: "winningStoriesGallery" */ 'Feature/ContentElements/code/Scripts/WinningStories/winningStoriesGallery');

if (ifExist('.mobileNavLink,.nav-mobile-meta-template,.nav-mobile,.nav-mobile-header')) import(/* webpackChunkName: "mobileNavigation" */ 'Feature/Navigation/code/Scripts/Navigation/mobileNavigation');

if (ifExist('.nav-item,.mainnav__subnavi,.mainnav__overlay,.mainnav_underline,.nav-item .nav-link[aria-haspopup="true"]')) import(/* webpackChunkName: "desktopNavigation" */ 'Feature/Navigation/code/Scripts/Navigation/desktopNavigation');

if (ifExist('.breadcrumb-navigation-container')) import(/* webpackChunkName: "breadcrumb" */ 'Feature/Navigation/code/Scripts/Navigation/breadcrumb');

if (ifExist('.chargeback, .chargeback__submitdata__form-data-container, .chargeback__summary__form-data-container')) import(/* webpackChunkName: "chargeBack" */ 'Feature/ChargeBack/code/Scripts/chargeBack');

if (ifExist('.frc-captcha')) import(/* webpackChunkName: "recaptcha" */ 'Feature/Forms/code/Scripts/recaptcha');

if (ifExist('.imageTextTeaser')) import(/* webpackChunkName: "teaserImageText" */ 'Feature/ContentElements/code/Scripts/TeaserImageText/teaserImageText');

if (ifExist('.imageTextTeaserHorizontal')) import(/* webpackChunkName: "imageTextTeaserHorizontal" */ 'Feature/ContentElements/code/Scripts/ImageTextTeaserHorizontal/imageTextTeaserHorizontal');

if (ifExist('#LotteryBundlePage')) import(/* webpackChunkName: "lotConfiguratorBundle" */ 'Feature/Shop/code/Scripts/LotConfiguratorBundle/lotConfiguratorBundle')
// components

import(/* webpackChunkName: "global" */ 'Common/Frontend/components/global.cmp');

import(/* webpackChunkName: "skiplinks" */ 'Common/Frontend/components/molecules/skiplinks/skiplinks.cmp');

import(/* webpackChunkName: "templatefooter" */ 'Common/Frontend/components/molecules/templatefooter/templatefooter.cmp');

import(/* webpackChunkName: "searchresultspager" */ 'Common/Frontend/components/molecules/searchresultspager/searchresultspager.cmp');

// on-demand components

if (ifExist('.link')) import(/* webpackChunkName: "link" */ 'Common/Frontend/components/atoms/link/link.cmp');

if (ifExist('.image')) import(/* webpackChunkName: "image" */ 'Common/Frontend/components/molecules/image/image.cmp');

if (ifExist('.stage')) import(/* webpackChunkName: "stage" */ 'Common/Frontend/components/molecules/stage/stage.cmp');

if (ifExist('.header')) import(/* webpackChunkName: "header" */ 'Common/Frontend/components/organisms/header/header.cmp');

if (ifExist('.footer')) import(/* webpackChunkName: "cookieBox" */ 'Common/Frontend/components/molecules/cookieBox/cookieBox.cmp');

if (ifExist('.linklist') || ifExist('.footer__linklist')) import(/* webpackChunkName: "linklist" */ 'Foundation/ContentElements/code/Linklist/Styles/linklist.scss');

if (ifExist('.footer')) import(/* webpackChunkName: "footermain" */ 'Common/Frontend/components/molecules/footermain/footermain.cmp');

if (ifExist('.videoTeaser')) import(/* webpackChunkName: "videoteaser" */ 'Common/Frontend/components/molecules/videoteaser/videoteaser.cmp');

if (ifExist('.videoPlayer')) import(/* webpackChunkName: "videoplayer" */ 'Foundation/ContentElements/code/MediaPlayer/VideoPlayer/Scripts/videoplayer.cmp');

if (ifExist('.audioPlayer')) import(/* webpackChunkName: "audioplayer" */ 'Foundation/ContentElements/code/MediaPlayer/AudioPlayer/Scripts/audioplayer.cmp');

if (ifExist('.accordion__module') || ifExist('.card__body')) import(/* webpackChunkName: "accordion" */ 'Common/Frontend/components/molecules/accordion/accordion.cmp');

if (ifExist('.newsLinklist')) import(/* webpackChunkName: "newslinklist" */ 'Common/Frontend/components/molecules/newslinklist/newslinklist.cmp');

if (ifExist('#newsletter-abmeldeformular')) import(/* webpackChunkName: "signout" */ 'Common/Frontend/components/molecules/signout/signout.cmp');

if (ifExist('.process-slider')) import(/* webpackChunkName: "processslider" */ 'Common/Frontend/components/molecules/processslider/processslider.cmp');

if (ifExist('.lottery-teaser')) import(/* webpackChunkName: "lotteryteaser" */ 'Common/Frontend/components/molecules/lotteryteaser/lotteryteaser.cmp');

if (ifExist('.footer__linklist')) import(/* webpackChunkName: "footerlinklist" */ 'Common/Frontend/components/molecules/footerlinklist/footerlinklist.cmp');

if (ifExist('.headline-feature')) import(/* webpackChunkName: "headline" */ 'Foundation/ContentElements/code/Common/Styles/headline.scss');

if (ifExist('.imageTeaserView')) import(/* webpackChunkName: "imageteaserview" */ 'Common/Frontend/components/molecules/imageteaserview/imageteaserview.cmp');

if (ifExist('.searchresult__list')) import(/* webpackChunkName: "searchresultlist" */ 'Common/Frontend/components/molecules/searchresultlist/searchresultlist.cmp');

if (ifExist('#headerMetaNavigationLeft, #headerMetaNavigationRight')) import(/* webpackChunkName: "metanavigation" */ 'Common/Frontend/components/molecules/metanavigation/metanavigation.cmp');

if (ifExist('#headerMetaNavigationSearch')) import(/* webpackChunkName: "metanavsearch" */ 'Common/Frontend/components/molecules/metanavsearch/metanavsearch.cmp');

if (ifExist('.footer__contact')) import(/* webpackChunkName: "footercontactteaser" */ 'Common/Frontend/components/molecules/footerContactTeaser/footercontactteaser.cmp');

if (ifExist('.raffle-ticket-check')) import(/* webpackChunkName: "raffleticketcheck" */ 'Common/Frontend/components/molecules/raffleticketcheck/raffleticketcheck.cmp');

if (ifExist('#headerMetaNavigationLoginLayer')) import(/* webpackChunkName: "metanavlogin" */ 'Common/Frontend/components/molecules/metanavlogin/metanavlogin.cmp');

if (ifExist('.metanavsignout-link')) import(/* webpackChunkName: "metanavsignoutlink" */ 'Common/Frontend/components/molecules/metanavsignoutlink/metanavsignoutlink.cmp');

if (ifExist('#headerMetaNavigationLoginSSO')) import(/* webpackChunkName: "metanavloginSSO" */ 'Common/Frontend/components/molecules/metanavloginSSO/metanavloginSSO.cmp');

if (ifExist('.link-button-container,.link-button,.app-link-button')) import(/* webpackChunkName: "linkbutton" */ 'Common/Frontend/components/atoms/linkbutton/linkbutton.cmp');


// Portal scripts and styles
if (!ifExistOnly('.lottery-page')) import(/* webpackChunkName: "Portal" */ 'Portal/Scripts/Portal');

// Lottery scripts and styles
if (ifExistOnly('.lottery-page')) import(/* webpackChunkName: "lottery" */ 'Lottery/Scripts/lottery');

if (import.meta.webpackHot) {
    import.meta.webpackHot.accept();
}